import _ from 'lodash'

export function toEnum(definition) {
  const enumObject = Object.create({
    toList: function () {
      return Object.values(this)
    },
    includes: function (value) {
      return this.toList().includes(value)
    },
  })

  _.merge(enumObject, definition)

  return Object.freeze(enumObject)
}

export const roles = toEnum({
  SITE_TECHNICIAN: 'siteTechnician', // Technicien de chantier
  PRODUCTION_TECHNICIAN_ASPHALT: 'productionTechnicianAsphalt', // Technicien de production enrobés
  PRODUCTION_TECHNICIAN_AGGREGATE: 'productionTechnicianAggregate', // Technicien de production granulats
  ROLLING_COMFORT: 'rollingComfort', // Confort de roulement
  PROJECT_MANAGER: 'projectOwner', // Chargé de projet
  SITE_FOREMAN: 'siteForeman', // Contremaitre de chantier
  SITE_SUPERINTENDENT: 'siteSuperintendent', // Surintendant de chantier
  PRODUCTION_FOREMAN: 'productionForeman', // Contremaitre de production
  ASPHALT_FOREMAN: 'asphaltForeman', // Contremaitre d'enrobés
  AGGREGATE_FOREMAN: 'aggregateForeman', // Contremaitre granulats
  // SITE_SUPERVISOR: 'siteSupervisor', // Surveillant
  CONTRACTOR: 'contractor', // Entrepreneur
  // ROGER: 'roger', // Roger
  TECHNICAL_MANAGER: 'technicalManager', // Responsable technique (petit Roger)
  TECHNICAL_DIRECTOR: 'technicalDirector', // Directeur technique (grand Roger)
  GENERAL_MANAGER_CONTRACTOR: 'generalManagerContractor',
  INVENTORY_MANAGER: 'inventoryManager', // Gestionnaire d'inventaire (défauts + actifs routiers)

  USER: 'user',
  SYSTEM_ADMINISTRATOR: 'admin', // Administrateur système
  PROJECT_ADMINISTRATOR: 'projectAdmin', // Administrateur de projet
  SUPER_ADMIN: 'superAdmin',
})

export const datapoints = toEnum({
  LAB_ASPHALT_SAMPLE_INFO: 'lab-asphalt-sample-info',
  LAB_AGGREGATE_SAMPLE_INFO: 'lab-aggregate-sample-info',
  LAB_BITUMEN_SAMPLE_INFO: 'lab-bitumen-sample-info',
  SITE_AGGREGATE_SAMPLE_TEST: 'site-aggregate-sample-test',
  SITE_AGGREGATE_SECTION: 'site-aggregate-section',
  SITE_AGGREGATE_TEST: 'site-aggregate-test',
  SITE_AGGREGATE_STAB_TEST: 'site-aggregate-stabilized-test',
  SITE_AGGREGATE_TESTSLAB_TEST: 'site-aggregate-testslab-test',
  SITE_ASPHALT_SAMPLE_TEST: 'site-asphalt-sampling-test',
  SITE_ASPHALT_THERMOGRAPHY_LOT: 'site-asphalt-thermography-lot',
  SITE_ASPHALT_SPREAD_RATE: 'site-asphalt-spread-rate',
  SITE_ASPHALT_SECTION: 'site-asphalt-section',
  SITE_ASPHALT_TEMPERATURE: 'site-asphalt-temperature',
  SITE_ASPHALT_COMPACTION_MTQ: 'site-asphalt-compaction-mtq',
  SITE_ASPHALT_COMPACTION_CONTRACTOR: 'site-asphalt-compaction-contractor',
  SITE_AGGREGATE_SAMPLE_INFO: 'site-aggregate-sample-info',
  SITE_AGGREGATE_INFO: 'site-asphalt-aggregate-info',
  SITE_AGGREGATE_STAB_INFO: 'site-aggregate-stabilized-info',
  SITE_AGGREGATE_TESTSLAB_INFO: 'site-aggregate-testslab-info',
  SITE_ASPHALT_SAMPLE_INFO: 'site-asphalt-sample-info',
  SITE_ASPHALT_THERMOGRAPHY_INFO: 'site-asphalt-thermography-info',
  SITE_ASPHALT_COMPACTION_INFO: 'site-asphalt-compaction-info',
  IMAGE: 'images',
  IRI: 'iri',
  MPD: 'mpd',
})

export const sources = toEnum({
  SITE_AGGREGATE_COMPACTION: 'site-aggregate',
  SITE_AGGREGATE_SAMPLE: 'site-aggregate-sampling',
  SITE_ASPHALT_COMPACTION: 'site-asphalt-compaction',
  SITE_ASPHALT_SAMPLE: 'site-asphalt-sampling',
  SITE_ASPHALT_THERMO: 'site-asphalt-thermography',
  LAB_AGGREGATE: 'lab-aggregate-sample',
  LAB_ASPHALT: 'lab-asphalt-sample',
  LAB_BITUMEN: 'lab-bitumen-sample',
  IMAGE: 'default-image-bucket',
  IRI_CLASS1: 'iri-class1',
  IRI_CLASS3: 'iri-class3',
  MPD: 'mpd-class1',
})

export const references = toEnum({
  LAB_AGGREGATE: 'lab-aggregate',
  LAB_ASPHALT: 'lab-asphalt',
  LAB_BITUMEN: 'lab-bitumen',
  LAB_MIX_DESIGN: 'lab-mixdesign',
  LAB_SPEC_COATING: 'lab-specification-coating',
  LAB_SPEC_MATERIAL: 'lab-specification-material',
  LAB_SPEC_BINDER: 'lab-specification-binder',
  LAB_MATERIAL: 'lab-material',
  SUPPLIER: 'supplier',
  PLANT: 'plant',
})

export const risk = Object.freeze({
  NA: 0,
  LR: 1,
  AR: 2,
  HR: 3,
})

export const trend = Object.freeze({
  NA: 0,
  UPWARD: 1,
  CONSTANT: 2,
  DOWNWARD: 3,
})

export const side = toEnum({
  LEFT: 0,
  RIGHT: 1,
})

export const deviceType = Object.freeze({
  class1: 'class1',
  class2: 'class2',
  class3: 'class3',
})

export const roadLayers = Object.freeze({
  rolling: 'rolling',
  correction: 'correction',
  intermediate: 'intermediate',
  base: 'base',
  leveling: 'leveling',
  coldReprocessing: 'cold-reprocessing',
  foundations: 'foundations',
})

export const roadCategories = Object.freeze({
  urban: 'urban',
  highway: 'highway',
  national: 'national',
  country: 'country',
  collector: 'collector',
  gravel: 'gravel',
  concrete: 'concrete',
})

export const defectTypes = toEnum({
  ALLIGATOR_CRACK: 'alligatorCrack',
  BLEEDING: 'bleeding',
  BLOCK_CRACK: 'blockCrack',
  BUMP: 'bump',
  CORNER_BREAKS: 'cornerBreaks',
  CORRUGATION: 'corrugation',
  CROWN: 'crown',
  DEPRESSION: 'depression',
  DITCH: 'ditch',
  DRAINAGE_ISSUE: 'drainageIssue',
  DUSTY_SURFACE: 'dustySurface',
  EDGE_BREAKS: 'edgeBreaks',
  EDGE_CRACK: 'edgeCrack',
  HOMOGENEOUS_MATERIALS: 'homogeneousMaterials',
  LONGITUDINAL_CRACK: 'longitudinalCrack',
  LOOSE_GRAVEL: 'looseGravel',
  PATCHING: 'patching',
  POPOUTS: 'popouts',
  POTHOLES: 'potholes',
  RAVELING: 'raveling',
  RUT: 'rut',
  SCALING: 'scaling',
  SAGGING: 'sagging',
  SHARP_ELEVATION: 'sharpElevation',
  SIDE_SLOPE: 'sideSlope',
  TRANSVERSE_CRACK: 'transverseCrack',
  UNEVEN_GRAVEL: 'unevenGravel',
  WASHING_OUT: 'washingOut',
  WIDTH_VARIATION: 'widthVariation',
})

export const assetTypes = toEnum({
  GUARD_RAILS: 'guardRails',
  LAMP_POSTS: 'lampPosts',
  ROAD_MARKINGS: 'roadMarkings',
  DANGER_SIGNS: 'dangerSigns',
  INDICATION_SIGNS: 'indicationSigns',
  PRESCRIPTION_SIGNS: 'prescriptionSigns',
})

export const roadDirections = Object.freeze({
  north: 'north',
  northEast: 'northEast',
  east: 'east',
  southEast: 'southEast',
  south: 'south',
  southWest: 'southWest',
  west: 'west',
  northWest: 'northWest',
})

export const iriFileTypes = Object.freeze({
  romdas: 'Romdas',
  roadBump: 'RoadBump',
  roadroid: 'Roadroid',
  roadroidSingle: 'RoadroidSingle',
})

export const projectTypes = Object.freeze({
  research: 'research',
  construction: 'contract',
  production: 'production',
  maintenance: 'maintenance',
})

export const projectStatuses = Object.freeze({
  NEW: 'new',
  PENDING: 'pending',
  ACTIVE: 'active',
  WARRANTY: 'warranty',
  DONE: 'done',
})

export const reportTypes = Object.freeze({
  externalReport: 'externalReport',
  onSiteChangeRequest: 'onSiteChangeRequest',
  observedIssue: 'observedIssue',
  recommendation: 'recommendation',
  other: 'other',
})

export const reportSources = Object.freeze({
  client: 'client',
  supervisor: 'supervisor',
  commissionedLaboratory: 'commissionedLaboratory',
  other: 'other',
})

export const reportStates = Object.freeze({
  processing: 'processing',
  noFollowUp: 'noFollowUp',
  dispute: 'dispute',
  closed: 'closed',
})

export const impactLevels = toEnum({
  critical: 1,
  urgent: 2,
  toConsider: 3,
  toEvaluate: 4,
  lowRisk: 5,
})

export const reportNatures = toEnum({
  COMPACTION: 'compaction',
  CONDITIONS: 'field-conditions',
  EQUIPMENT: 'equipment',
  MATERIAL: 'material',
  OTHER: 'other',
  SAMPLING: 'sampling',
  TEMPERATURE: 'temperature',
  THERMOGRAPHY: 'thermography',
  THICKNESS: 'thickness',
})

export const AGGREGATE_SITE_GLOBAL_TYPES = [
  datapoints.SITE_AGGREGATE_SAMPLE_INFO,
  datapoints.SITE_AGGREGATE_INFO,
  datapoints.SITE_AGGREGATE_STAB_INFO,
  datapoints.SITE_AGGREGATE_TESTSLAB_INFO,
]

export const ASPHALT_SITE_GLOBAL_TYPES = [
  datapoints.SITE_ASPHALT_SAMPLE_INFO,
  datapoints.SITE_ASPHALT_THERMOGRAPHY_INFO,
  datapoints.SITE_ASPHALT_COMPACTION_INFO,
]

export const asphaltSamplePropertyNames = toEnum({
  TG: 'TG',
  FIVE_MM: '5mm',
  EIGHTY_UM: '80um',
  BITUMEN_CONTENT: 'bitumenContent',
  MAX_DENSITY: 'maxDensity',
  MARSHAL_VOIDS: 'voids',
  NINI_VOIDS: 'voidsNini',
  NDES_VOIDS: 'voidsNdes',
  NMAX_VOIDS: 'voidsNmax',
})

export const materialSamplePropertyNames = toEnum({
  TWENTY_MM: '20mm',
  FOURTEEN_MM: '14mm',
  TEN_MM: '10mm',
  FIVE_MM: '5mm',
  TWOPOINTFIVE_MM: '2_5mm',
  EIGHTY_UM: '80um',
  BULK_DENSITY: 'bulkDensity',
  APPARENT_DENSITY: 'apparentDensity',
  ABSORPTION: 'absorption',
  MAX_DENSITY: 'maxDensity',
  BITUMEN_CONTENT: 'bitumenContent',
})

export const aggregateSamplePropertyNames = toEnum({
  TWENTY_MM: '20mm',
  FOURTEEN_MM: '14mm',
  TEN_MM: '10mm',
  FIVE_MM: '5mm',
  TWOPOINTFIVE_MM: '2_5mm',
  EIGHTY_UM: '80um',
  CPP: 'CPP',
  ABSORPTION: 'absorption',
  APPARENT_DENSITY: 'apparentDensity',
  BITUMEN_CONTENT: 'bitumenContent',
  BLUE: 'blue',
  BULK_DENSITY: 'bulkDensity',
  COARSE_MICRO_DEVAL: 'coarseMicroDeval',
  ELONGATED_PARTICLES: 'elongatedParticles',
  FINE_MICRO_DEVAL: 'fineMicroDeval',
  FLAT_PARTICLES: 'flatParticles',
  FRACTURED_PARTICLES: 'fracturedParticles',
  FRIABILITY: 'friability',
  LOS_ANGELES: 'losAngeles',
  MAX_DENSITY: 'maxDensity',
})

export const productTypes = toEnum({
  ASPHALT: 'asphalt',
  AGGREGATE: 'aggregate',
  MATERIAL: 'material',
})

export const IRIGranularities = toEnum({
  TEN_METERS: 10,
  HUNDRED_METERS: 100,
})

export const kpiTypes = Object.freeze({
  ECONFORMITE: 'EConformite',
  EDISTBG: 'EDistBG',
  EDISTBD: 'EDistBD',
  EFREQDISTI: 'EFreqdisti',
  EFREQUENCE1: 'EFrequence1',
  EHOMOGENEITE: 'EHomogeneite',
  GCONFORMITE: 'GConformite',
  GDISTBG: 'GDistBG',
  GDISTBD: 'GDistBD',
  GFREQDISTI: 'GFreqdisti',
  GFREQUENCE1: 'GFrequence1',
  GHOMOGENEITE: 'GHomogeneite',
  GFREQUENCEESSAI: 'GFrequenceessai',
  MFIABIT: 'MFiabit',
  MFIAESSAI: 'MFiaessai',
  MFREQUENCEESSAI: 'MFrequenceessai',
  MQUALMEL: 'MQualMel',
  RFREQUENCETEMPMOY: 'RFrequenceTempMoy',
  RTHERMOFREQUENCEMOY: 'RThermoFrequenceMoy',
  RTHERMOAVIS: 'RThermoAvis',
  RTEMPVAR: 'RTempVar',
  RUNI: 'RUni',
  RTPOSE: 'RTPose',
  RTPOSEFREQUENCE: 'RTPoseFrequence',
  RDELAIS: 'RDelais',
  RMEMO: 'RMemo',
  IQCE: 'IQCE',
  IQCSG: 'IQCSG',
  IQDDM: 'IQDDM',
  IQDEL: 'IQDel',
  IQGLOBALTRAVAUX: 'IQGlobalTravaux',
  IQGLOBALPROD: 'IQGlobalProd',
  IQGLOBALBUSINESS: 'IQGlobalBusiness',
  IQTTBMTC: 'IQTTBMTC',
  IQRE: 'IQRE',
  IQRECH: 'IQRECH',
  IQTRAVAUX: 'IQTravaux',
  IQTBMCPE: 'IQTBMCPE',
  IPECHP: 'IPECHP',
  IQDUG: 'IQDUG',
  IQDUM: 'IQDUM',
  IQEQMEL: 'IQEQMEL',
  IQGQMAT: 'IQGQMAT',
  RCRPIP: 'RCRPIP',
  ICRPV: 'ICRPV',
  IQTBMCC: 'IQTBMCC',
  IRLITIGE: 'IRLitige',
  IVERTPROJET: 'IVertProjet',
  IQTBMCRP: 'IQTBMCRP',
  IQTBMPE: 'IQTBMPE',
  IQTBMPG: 'IQTBMPG',
  IQDDMP: 'IQDDMP',
  IQDDGP: 'IQDDGP',
  MESSAISUIVI: 'MEssaiSuivi',
  MSUIVI: 'MSuivi',
  MVARMEL: 'MVarMel',
  MCHANTIER: 'MChantier',
  MQUALCONST: 'MQualConst',
  MQUALBIT: 'MQualBit',
  MVVIDESPCG: 'MVVidespcg',
  MVIDESCPD: 'MVidesCPd',
  MVVIDESPCGNDES: 'MVVidespcgNdes',
  MVVIDESPCGNINI: 'MVVidespcgNini',
  MVIDESCM: 'MVidesCM',
  MVIDESCPI: 'MVidesCPi',
  MVVIDESM: 'MVVidesm',
  MTG: 'MTG',
  MM5MM: 'MM5mm',
  MM80UM: 'MM80um',
  MMMAX_1MM: 'MMMax-1mm',
  MMMAXMM: 'MMMaxmm',
  MDMAX: 'MDmax',
  MDMAX1: 'MDmax1',
  MBITU: 'MBitu',
  MTEMPCONT: 'Mtempcont',
  MBITCONTCOMP: 'MBitContcomp',
  MBITCONTSOLL: 'MBitContsoll',
  MBITCONTTYPE: 'MBitContType',
  MECART_TG: 'MEcart_TG',
  MECART_BIT: 'MEcart_Bit',
  MECART_5MM: 'MEcart_5mm',
  MECART_80UM: 'MEcart_80um',
  MECART_MAXMM: 'MEcart_Maxmm',
  MECART_MAX_1MM: 'MEcart_Max-1mm',
  MECART_VIDES: 'MEcart_Vides',
  MECART_DMAX: 'MEcart_Dmax',
  PARAMXMED: 'ParamXMed',
  GFIAESSAI: 'GFiaessai',
  GQUALMAT: 'GQualMat',
  GESSAISUIVI: 'GEssaiSuivi',
  GSUIVI: 'Gsuivi',
  GVARMATPROD: 'GVarMatProd',
  GVARMATQUA: 'GVarMatQua',
  GVARMATRECY: 'GVarMatRecy',
  GECART_GMDEV: 'GEcart_GMDev',
  GECART_GLA: 'GEcart_GLa',
  GECART_GCPP: 'GEcart_GCPP',
  GECART_GMORG: 'GEcart_GMOrg',
  GECART_GFRIA: 'GEcart_GFria',
  GECART_GBLEU: 'GEcart_GBleu',
  GECART_GFRAC: 'GEcart_GFrac',
  GECART_GGROS: 'GEcart_Ggros',
  GFUSEAUX_GGROS: 'GFuseaux_Ggros',
  GECART_G80: 'GEcart_G80',
  GFUSEAUX_G80: 'GFuseaux_G80',
  GECART_GFINS: 'GEcart_Gfins',
  GFUSEAUX_GFINS: 'GFuseaux_Gfins',
  GFUSEAUX_GMDEV: 'GFuseaux_GMDev',
  GFUSEAUX_GLA: 'GFuseaux_GLa',
  GFUSEAUX_GCPP: 'GFuseaux_GCPP',
  GFUSEAUX_GFRIA: 'GFuseaux_GFria',
  GFUSEAUX_GMORG: 'GFuseaux_GMOrg',
  GFUSEAUX_GBLEU: 'GFuseaux_GBleu',
  GFUSEAUX_GFRAC: 'GFuseaux_GFrac',
  GECART_GBIT: 'GEcart_GBit',
  GECART_GDMAX: 'GEcart_GDmax',
  GECART_DENS: 'GEcart_Dens',
  GECART_PFORM: 'GEcart_PForm',
})
